.flex {
  display: flex;
  align-self: center;
}

[tooltip] {
  position: relative;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
}

/* hide empty tooltip */
[tooltip=''] {
  display: none;
}

[tooltip]::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  z-index: 99;
  opacity: 0;
}

[tooltip][flow='right']::before {
  left: 20px;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg);
}

[tooltip][flow='top']::before {
  left: 50%;
}

[tooltip][flow='bottom']::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}

[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: #293d56;
  text-align: center;
  color: #fff;
  padding: 10px 8px;
  font-size: 12px;
  line-height: 14px;
  min-width: 120px;
  width: auto;
  border-radius: 4px;
  pointer-events: none;
  z-index: 99;
  opacity: 0;
}

[tooltip][flow='top']::after {
  left: 50%;
}

[tooltip][flow='bottom']::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}

[tooltip][flow='right']::after {
  left: 20px;
  top: 50%;
  margin-left: 12px;
  transform: translateX(0%) translateY(-50%);
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1;
}
